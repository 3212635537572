var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.title,
        visible: _vm.showDialog,
        width: "1000px",
        top: "5vh",
        "close-on-click-modal": false,
        modal: false,
        "destroy-on-close": "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.showDialog = $event
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "orderTest-container" },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.form,
                "label-position": "right",
                "label-width": "80px",
                "label-suffix": ":",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "内容说明", prop: "title" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "220px" },
                    model: {
                      value: _vm.form.title,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "title", $$v)
                      },
                      expression: "form.title",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "起止时间", prop: "" } },
                [
                  _c("el-date-picker", {
                    attrs: {
                      type: "daterange",
                      "range-separator": "-",
                      "value-format": "yyyy-MM-dd",
                      "start-placeholder": "开始日期",
                      "end-placeholder": "结束日期",
                    },
                    model: {
                      value: _vm.time,
                      callback: function ($$v) {
                        _vm.time = $$v
                      },
                      expression: "time",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "优先级", prop: "sort_order" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "220px" },
                    attrs: { type: "number" },
                    model: {
                      value: _vm.form.sort_order,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "sort_order", $$v)
                      },
                      expression: "form.sort_order",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c("div", { staticClass: "pic-wrap" }, [
            _c("div", { staticClass: "android" }, [
              _c("h2", { staticStyle: { "text-align": "center" } }, [
                _vm._v("安卓"),
              ]),
              _c(
                "div",
                { staticClass: "box" },
                [
                  _c(
                    "el-upload",
                    {
                      staticClass: "avatar-uploader",
                      attrs: {
                        action: _vm.baseURL + "/baseAdmin/common/upload-image",
                        "show-file-list": false,
                        headers: _vm.headers,
                        name: "files",
                        accept: ".png, .jpg, .jpeg, .gif, .bmp, .jfif",
                        "on-success": _vm.handleAndroidSuccess,
                      },
                    },
                    [
                      _vm.form.android_pic
                        ? _c("img", {
                            staticClass: "avatar",
                            attrs: { src: _vm.form.android_pic },
                          })
                        : _c("i", {
                            staticClass: "el-icon-plus avatar-uploader-icon",
                          }),
                    ]
                  ),
                ],
                1
              ),
              _c("h3", { staticStyle: { "text-align": "center" } }, [
                _vm._v("1440*2560"),
              ]),
            ]),
            _c("div", { staticClass: "ios" }, [
              _c("h2", { staticStyle: { "text-align": "center" } }, [
                _vm._v("苹果"),
              ]),
              _c("div", { staticClass: "ios-up" }, [
                _c("div", { staticClass: "w" }, [
                  _c(
                    "div",
                    {
                      staticClass: "box",
                      staticStyle: { "margin-right": "20px" },
                    },
                    [
                      _c(
                        "el-upload",
                        {
                          staticClass: "avatar-uploader",
                          attrs: {
                            action:
                              _vm.baseURL + "/baseAdmin/common/upload-image",
                            "show-file-list": false,
                            headers: _vm.headers,
                            name: "files",
                            accept: ".png, .jpg, .jpeg, .gif, .bmp, .jfif",
                            "on-success": _vm.handleIos1Success,
                          },
                        },
                        [
                          _vm.form.ios_pic
                            ? _c("img", {
                                staticClass: "avatar",
                                attrs: { src: _vm.form.ios_pic },
                              })
                            : _c("i", {
                                staticClass:
                                  "el-icon-plus avatar-uploader-icon",
                              }),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c("h3", { staticStyle: { "text-align": "center" } }, [
                    _vm._v("手机：1242*2688"),
                  ]),
                ]),
                _c("div", { staticClass: "w" }, [
                  _c(
                    "div",
                    { staticClass: "box" },
                    [
                      _c(
                        "el-upload",
                        {
                          staticClass: "avatar-uploader",
                          attrs: {
                            action:
                              _vm.baseURL + "/baseAdmin/common/upload-image",
                            "show-file-list": false,
                            headers: _vm.headers,
                            name: "files",
                            accept: ".png, .jpg, .jpeg, .gif, .bmp, .jfif",
                            "on-success": _vm.handleIos2Success,
                          },
                        },
                        [
                          _vm.form.ipad_pic
                            ? _c("img", {
                                staticClass: "avatar",
                                attrs: { src: _vm.form.ipad_pic },
                              })
                            : _c("i", {
                                staticClass:
                                  "el-icon-plus avatar-uploader-icon",
                              }),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c("h3", { staticStyle: { "text-align": "center" } }, [
                    _vm._v("IPAD：1536*2048"),
                  ]),
                ]),
              ]),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "footer" },
            [
              _vm.type == 2
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.handleSave },
                    },
                    [_vm._v(" 保存 ")]
                  )
                : _vm._e(),
              _vm.type == 1
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.handleAdd },
                    },
                    [_vm._v(" 添加 ")]
                  )
                : _vm._e(),
              _c("el-button", { on: { click: _vm.close } }, [_vm._v("关闭")]),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }