<template>
  <el-dialog
    :title="title"
    :visible.sync="showDialog"
    width="1000px"
    top="5vh"
    :close-on-click-modal="false"
    :modal="false"
    destroy-on-close
  >
    <div class="orderTest-container">
      <el-form
        ref="form"
        :model="form"
        label-position="right"
        label-width="80px"
        label-suffix=":"
      >
        <el-form-item label="内容说明" prop="title">
          <el-input v-model="form.title" style="width: 220px"></el-input>
        </el-form-item>
        <el-form-item label="起止时间" prop="">
          <el-date-picker
            v-model="time"
            type="daterange"
            range-separator="-"
            value-format="yyyy-MM-dd"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="优先级" prop="sort_order">
          <el-input
            v-model="form.sort_order"
            type="number"
            style="width: 220px"
          ></el-input>
        </el-form-item>
      </el-form>
      <div class="pic-wrap">
        <div class="android">
          <h2 style="text-align: center">安卓</h2>
          <div class="box">
            <el-upload
              class="avatar-uploader"
              :action="baseURL + '/baseAdmin/common/upload-image'"
              :show-file-list="false"
              :headers="headers"
              name="files"
              accept=".png, .jpg, .jpeg, .gif, .bmp, .jfif"
              :on-success="handleAndroidSuccess"
            >
              <img
                v-if="form.android_pic"
                :src="form.android_pic"
                class="avatar"
              />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </div>
          <h3 style="text-align: center">1440*2560</h3>
        </div>
        <div class="ios">
          <h2 style="text-align: center">苹果</h2>
          <div class="ios-up">
            <div class="w">
              <div class="box" style="margin-right: 20px">
                <el-upload
                  class="avatar-uploader"
                  :action="baseURL + '/baseAdmin/common/upload-image'"
                  :show-file-list="false"
                  :headers="headers"
                  name="files"
                  accept=".png, .jpg, .jpeg, .gif, .bmp, .jfif"
                  :on-success="handleIos1Success"
                >
                  <img v-if="form.ios_pic" :src="form.ios_pic" class="avatar" />
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                  <!-- <div class="close el-icon-error"></div> -->
                </el-upload>
              </div>
              <h3 style="text-align: center">手机：1242*2688</h3>
            </div>
            <div class="w">
              <div class="box">
                <el-upload
                  class="avatar-uploader"
                  :action="baseURL + '/baseAdmin/common/upload-image'"
                  :show-file-list="false"
                  :headers="headers"
                  name="files"
                  accept=".png, .jpg, .jpeg, .gif, .bmp, .jfif"
                  :on-success="handleIos2Success"
                >
                  <img
                    v-if="form.ipad_pic"
                    :src="form.ipad_pic"
                    class="avatar"
                  />
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
              </div>
              <h3 style="text-align: center">IPAD：1536*2048</h3>
            </div>
          </div>
        </div>
      </div>
      <div class="footer">
        <el-button v-if="type == 2" type="primary" @click="handleSave">
          保存
        </el-button>
        <el-button v-if="type == 1" type="primary" @click="handleAdd">
          添加
        </el-button>
        <el-button @click="close">关闭</el-button>
      </div>
    </div>
  </el-dialog>
</template>
<script>
  import { createLaunch, editLaunch, getDetail } from '@/api/admin/appDev'
  import { baseURL } from '@/config'
  export default {
    name: 'Edit',
    components: {},
    data() {
      return {
        id: null,
        type: 1, // 1添加  2编辑
        loading: false,
        title: '标题',
        showDialog: false,
        apiUrl: '',
        time: [],
        headers: {},
        form: {
          title: '', //内容说明
          start_time: '', //开始时间
          end_time: '', //结束时间
          android_pic: '', //安卓图片
          ios_pic: '', //ios图片
          ipad_pic: '', //ipad图片
          sort_order: '', //优先级，默认0
        },
        androidImageUrl: '',
        ios1ImageUrl: '',
        ios2ImageUrl: '',

        androidImage: '',
        ios1Image: '',
        ios2Image: '',
      }
    },
    computed: {
      baseURL() {
        return baseURL
      },
    },
    watch: {
      time(val) {
        if (val) {
          this.form.start_time = val[0]
          this.form.end_time = val[1]
        } else {
          this.form.start_time = ''
          this.form.end_time = ''
        }
      },
      showDialog(val) {
        if (!val) {
          this.clearForm()
        } else {
          this.fetchData()
        }
      },
    },
    created() {
      console.log('baseURL', baseURL)
      console.log(process.env.NODE_ENV)
      this.title = process.env.NODE_ENV
      this.headers.token = this.$store.getters['user/accessToken']
    },
    mounted() {},
    methods: {
      clearForm() {
        this.time = []
        this.form = {
          title: '', //内容说明
          start_time: '', //开始时间
          end_time: '', //结束时间
          android_pic: '', //安卓图片
          ios_pic: '', //ios图片
          ipad_pic: '', //ipad图片
          sort_order: '', //优先级，默认0
        }
      },
      // 获取详情
      async fetchData() {
        if (this.type == 2) {
          this.loading = true
          let { data } = await getDetail({ id: this.id })
          this.form = data
          this.time = [this.form.start_time, this.form.end_time]

          this.loading = false
        }
      },
      // 安卓上传成功
      handleAndroidSuccess(res, file) {
        console.log('res', res, file)
        this.form.android_pic = res.data[0].url
        this.$message.success('上传成功')
      },
      // ios 上传成功
      handleIos1Success(res, file) {
        this.form.ios_pic = res.data[0].url
        this.$message.success('上传成功')
      },
      handleIos2Success(res, file) {
        this.form.ipad_pic = res.data[0].url
        this.$message.success('上传成功')
      },
      // 保存
      async handleSave() {
        try {
          let { data } = await editLaunch(this.form)
          this.$message.success('修改成功')
          this.$emit('refresh')
          this.close()
        } catch (err) {
          console.log('err', err)
        }
      },
      async handleAdd() {
        try {
          let { data } = await createLaunch(this.form)
          this.$message.success('添加成功')
          this.$emit('refresh')
          this.close()
        } catch (err) {
          console.log('err', err)
        }
      },
      close() {
        this.showDialog = false
      },
      // beforeAvatarUpload(file) {
      //   const isJPG = file.type === 'image/jpeg'
      //   const isLt2M = file.size / 1024 / 1024 < 2

      //   if (!isJPG) {
      //     this.$message.error('上传头像图片只能是 JPG 格式!')
      //   }
      //   if (!isLt2M) {
      //     this.$message.error('上传头像图片大小不能超过 2MB!')
      //   }
      //   return isJPG && isLt2M
      // },
    },
  }
</script>
<style lang="scss" scoped>
  .pic-wrap {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-around;
  }
  .ios-up {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-around;
  }
  .box {
    position: relative;
    cursor: pointer;
    border: 1px dashed #413c3c;
    border-radius: 6px;
  }
  .close {
    position: absolute;
    top: -8px;
    right: -8px;
    z-index: 99999;
    font-size: 28px;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }
  .avatar-uploader-icon {
    width: 178px;
    height: 178px;
    font-size: 28px;
    line-height: 178px;
    color: #8c939d;
    text-align: center;
  }
  .avatar {
    display: block;
    width: 178px;
    height: 178px;
  }
  .footer {
    margin-top: 10px;
    text-align: right;
  }
</style>
