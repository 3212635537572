<template>
  <div class="orderTest-container">
    <el-form
      ref="form"
      :model="form"
      inline
      label-position="right"
      label-width="80px"
    >
      <el-form-item label="" prop="title">
        <el-input v-model="form.title" placeholder="内容关键字"></el-input>
      </el-form-item>
      <el-form-item label="" prop="">
        <el-select
          v-model="form.status"
          clearable
          style="width: 160px"
          placeholder="状态"
        >
          <el-option
            v-for="(i, idx) in statusList"
            :key="idx"
            :value="i.id"
            :label="i.name"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="" prop="">
        <el-button type="primary" @click="fetchData">查询</el-button>
      </el-form-item>
      <el-form-item label="" prop="">
        <el-button type="primary" @click="handleAdd">添加</el-button>
      </el-form-item>
    </el-form>
    <el-table v-loading="loading" stripe :data="list">
      <!-- 序号 -->
      <el-table-column align="center" type="index" width="50">
        <!-- <template slot='header'>
          <el-popover popper-class='custom-table-checkbox' trigger='hover'>
            <el-checkbox-group v-model='checkList'>
              <el-checkbox
                v-for='(item, index) in columns'
                :key='index'
                :label='item.label'
              ></el-checkbox>
            </el-checkbox-group>
            <el-button slot='reference' type='text'>
              <vab-remix-icon icon='settings-line' />
            </el-button>
          </el-popover>
        </template> -->
      </el-table-column>
      <el-table-column
        v-for="(item, tableIndex) in finallyColumns"
        :key="tableIndex"
        :prop="item.prop"
        :label="item.label"
        width=""
        align="center"
      ></el-table-column>
      <el-table-column
        prop=""
        align="center"
        label="操作"
        fixed="right"
        min-width="130px"
      >
        <template #default="{ $index, row }">
          <el-button
            v-if="row.sort == 3 || row.sort == 5"
            type="text"
            @click.native.prevent="handleEdit($index, row)"
          >
            编辑
          </el-button>
          <el-button
            v-if="row.status == 1 && row.sort != 4"
            type="text"
            @click.native.prevent="handleToggleStatus($index, row)"
          >
            停用
          </el-button>
          <el-button
            v-if="row.status == 2 && row.sort != 4"
            type="text"
            @click.native.prevent="handleToggleStatus($index, row)"
          >
            启用
          </el-button>
          <el-button
            v-if="row.sort != 3"
            type="text"
            @click.native.prevent="handleDelete($index, row)"
          >
            删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <edit ref="edit" @refresh="fetchData"></edit>
  </div>
</template>
<script>
  import _ from 'lodash'
  import Edit from './components/edit'
  import { getList, toggleStatus, deleteRow } from '@/api/admin/appDev'
  export default {
    name: 'LaunchPic',
    components: { Edit },
    data() {
      return {
        loading: false,
        form: {
          title: '', // 内容关键字
          pageSize: 10,
          pageNo: 1,
          status: '',
        },
        // 状态2停用3显示中4过期5未开始
        statusList: [
          {
            id: 2,
            name: '停用',
          },
          {
            id: 3,
            name: '显示中',
          },
          {
            id: 4,
            name: '过期',
          },
          {
            id: 5,
            name: '未开始',
          },
        ],
        list: [],
        checkList: ['内容说明', '开始时间', '结束时间', '优先级', '状态'],
        columns: [
          {
            order: 1,
            label: '内容说明',
            prop: 'title',
            width: '200',
          },
          {
            order: 2,
            label: '开始时间',
            prop: 'start_time',
            width: '',
          },
          {
            order: 3,
            label: '结束时间',
            prop: 'end_time',
            width: '',
          },
          {
            order: 4,
            label: '优先级',
            prop: 'sort_order',
            width: '',
          },
          {
            order: 5,
            label: '状态',
            prop: 'status_text',
            width: '',
          },
        ],
      }
    },
    computed: {
      finallyColumns() {
        let finallyArray = []
        this.checkList.forEach((checkItem) => {
          finallyArray.push(
            this.columns.filter((item) => item.label === checkItem)[0]
          )
        })
        return _.sortBy(finallyArray, (item) => item.order)
      },
    },
    watch: {},
    created() {},
    mounted() {
      this.fetchData()
    },
    methods: {
      async fetchData() {
        this.loading = true
        let { data } = await getList(this.form)
        this.list = data
        this.loading = false
      },
      handleAdd() {
        this.$refs.edit.type = 1
        this.$refs.edit.title = '添加启动图'
        this.$refs.edit.showDialog = true
      },

      // table
      handleEdit(index, row) {
        this.$refs.edit.type = 2
        this.$refs.edit.id = row.id
        this.$refs.edit.title = '修改启动图'
        this.$refs.edit.showDialog = true
      },
      handleToggleStatus(index, row) {
        toggleStatus({
          id: row.id,
        }).then((res) => {
          this.fetchData()
          this.$message.success(res.msg)
        })
      },
      handleDelete(index, row) {
        this.$confirm('确定删除此项吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            deleteRow({ id: row.id }).then((res) => {
              this.fetchData()
              this.$message.success(res.msg)
            })
          })
          .catch(() => {
            console.log('no')
          })
      },
    },
  }
</script>
<style lang="scss" scoped></style>
